
import { Component, Vue, Inject, Prop } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import UpdateUi from "@/components/Core/Update/UpdateUI.vue";
import EquipmentBrandAutocomplete from "@/components/General/Autocompletes/Equipments/EquipmentBrandAutocomplete.vue";

import { PenetrantItem } from "@/domain";

import { IPenetrantService } from "@/services";

@Component({
  components: {
    UpdateUi,
    EquipmentBrandAutocomplete,
  },
})
export default class PenetrantUpdate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  item!: PenetrantItem;

  @Prop({ required: true })
  forbiddenSerialNumbers!: string[];

  get penetrantService() {
    return this.container.resolve<IPenetrantService>(S.PENETRANT_SERVICE);
  }

  get fluorescentChoices() {
    return [
      {
        value: true,
        text: "Fluorescent",
      },
      {
        value: false,
        text: "Non Fluorescent",
      },
    ];
  }

  loading: boolean = false;
  serialNumber: string | null = null;
  equipmentType: string | null = null;
  equipmentBrandId: string | null = null;
  pvNumber: string | null = null;
  isFluorescent: boolean = false;

  mounted() {
    this.serialNumber = this.item.serialNumber;
    this.equipmentType = this.item.equipmentType;
    this.equipmentBrandId = this.item.equipmentBrand.id;
    this.pvNumber = this.item.pvNumber;
    this.isFluorescent = this.item.isFluorescent;
  }

  isAllowed(serialNumber: string) {
    return (
      _.findIndex(
        this.forbiddenSerialNumbers,
        (v) => v.toUpperCase() == serialNumber.toUpperCase()
      ) != -1 &&
      this.item.serialNumber.toUpperCase() != serialNumber.toUpperCase()
    );
  }

  async update() {
    try {
      this.loading = true;
      const updatedPenetrant = await this.penetrantService.update(
        this.item.id,
        {
          serialNumber: this.serialNumber!,
          equipmentType: this.equipmentType!,
          equipmentBrandId: this.equipmentBrandId!,
          pvNumber: this.pvNumber!,
          isFluorescent: this.isFluorescent,
          disabled: this.item.disabled,
        }
      );
      this.$emit("update", updatedPenetrant);
    } finally {
      this.loading = false;
    }
  }
}
